import commonHelper from '@/app/utility/common.helper.utility';
import requestDetails from './requestDetails';
export default {
  name: 'requestMaster',
  components: {
    requestDetails
  },
  watch: {
    currentPage: function() {
      this.getRequestList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRequestList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      requestDetails: null,
      showRequestModal: false,
      showValueSetModal: false,
      loader: false,
      payload: {},
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      name: '',
      type: {
        value: null,
        text: null
      },
      data: [],
      fields: [
        {
          key: 'report_group_id',
          class: 'd-none'
        },
        {
          key: 'report_group_name',
          class: 'd-none'
        },
        {
          key: 'request_type_vset',
          class: 'd-none'
        },
        {
          key: 'template_id',
          class: 'd-none'
        },
        {
          key: 'request_id',
          class: 'd-none'
        },
        {
          key: 'request_name',
          label: 'Request Name'
        },
        {
          key: 'module_name',
          label: 'Module'
        },
        {
          key: 'parameters',
          label: 'Parameter'
        },
        {
          key: 'request_type',
          label: 'Request Type'
        },
        {
          key: 'template_name',
          class: 'd-none'
        },
        {
          key: 'creation_date',
          label: 'Creation Date'
        },
        {
          key: 'child_req_count'
        },
        {
          key: 'active'
        },
        {
          key: 'parent_flag'
        },
        {
          key: 'bursting_flag'
        },
        {
          key: 'email_flag'
        },
        {
          key: 'sms_flag'
        },
        {
          key: 'whatsapp_flag',
          label: 'WhatsApp Flag'
        }
      ]
    };
  },
  mounted() {
    this.getRequestList();

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;

        if (this.showRequestModal) {
          this.eventBus.$emit('respFormActions', actionName);
        }
        if (actionName === 'add') {
          this.editMode = true;
          this.showRequestModal = true;
        }
        if (actionName === 'download' && !this.showRequestModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'requestMaster/getRequestList',
            'rqst-request',
            () => (this.loader = false)
          );
        }
      }
    });
  },

  methods: {
    resetModal() {},
    rowSelected(rowData) {
      this.showRequestModal = true;
      setTimeout(() => {
        this.eventBus.$emit('requestFormHeader', rowData);
      }, 0);
    },
    clearRequest() {
      this.name = null;
    },
    updateRequestList() {
      this.getRequestList();
    },
    getRequestList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        request_name: this.name,
        request_type_vset: this.type.value
      };
      this.loaader = true;
      this.$store
        .dispatch('requestMaster/getRequestList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        });
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === 'REQUEST_TYPE') {
        this.type.text = item.value_meaning;
        this.type.value = item.value_code;
      }
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'REQUEST_TYPE') {
        this.type.text = null;
        this.type.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import ModulesList from '../../../admin/responsibility/searchModule';
export default {
  name: 'requestDetails',
  components: {
    DatePicker,
    ModelSelect,
    ModulesList
  },
  data() {
    return {
      requestDetails: null,
      editMode: true,
      showAlert: false,
      responseMsg: null,
      isSuccess: false,
      loader: false,
      commTempName: null,
      whatsapp_comm_template_id: null,
      whatsapp_comm_template: null,
      sms_comm_template_id: null,
      sms_comm_template: null,
      email_comm_template_id: null,
      email_comm_template: null,
      child_req_count: null,
      showValueSetModal: false,
      setTimeVsetCode: null,
      vsetCode: null,
      showRequestModal: false,
      burstingFlag: false,
      parentFlag: false,
      email: null,
      whatsapp: null,
      sms: null,
      requestTypeArr: [],
      data: [],
      requestForm: {
        selectedRequestType: {
          value: null,
          text: null
        },
        request_id: 0,
        request_name: null,
        template: {
          value: null,
          text: null
        },
        creation_date: null,
        selectedModule: {
          name: null,
          id: null
        },
      },
      tab: null,
      showModal: false,
    };
  },
  mounted() {
    this.getRequestType();

    this.eventBus.$on('requestFormHeader', obj => {
      this.editMode = false;
      this.requestForm.request_id = obj.request_id;
      this.requestForm.request_name = obj.request_name;
      this.requestForm.selectedRequestType.value = obj.request_type_vset;
      this.requestForm.selectedRequestType.text = obj.request_type;
      this.requestForm.template.value = obj.template_id;
      this.requestForm.template.text = obj.template_name;
      this.requestForm.creation_date = obj.creation_date;
      this.requestForm.selectedModule.id = obj.module_id;
      this.requestForm.selectedModule.name = obj.module_name;
      this.parentFlag = obj.parent_flag;
      this.burstingFlag = obj.bursting_flag;
      this.email = obj.email;
      this.sms = obj.sms;
      this.whatsapp = obj.whatsapp;
      this.whatsapp_comm_template_id = obj.whatsapp_comm_template_id;
      this.whatsapp_comm_template = obj.whatsapp_comm_template;
      this.sms_comm_template_id = obj.sms_comm_template_id;
      this.sms_comm_template = obj.sms_comm_template;
      this.email_comm_template_id = obj.email_comm_template_id;
      this.email_comm_template = obj.email_comm_template;
      this.child_req_count = obj.child_req_count;
    });
    this.eventBus.$off('respFormActions');
    this.eventBus.$on('respFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = true;
        this.update = true;
      }
      if (actionName === 'add') {
        this.editMode = true;
        this.requestForm.request_id = 0;
      }
      if (actionName === 'save' && this.editMode) {
        this.addEditRequest();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', {
          id: this.requestForm.selectedRequestType
        });
      }
    });
  },
  methods: {
    resetModal() { },
    addEditRequest() {
      const payload = {
        request_id: this.requestForm.request_id,
        request_name: this.requestForm.request_name,
        request_type_vset: this.requestForm.selectedRequestType.value,
        parent_flag: this.parentFlag,
        bursting_flag: this.burstingFlag,
        email: this.email,
        sms: this.sms,
        whatsapp: this.whatsapp,
        child_req_count: this.child_req_count,
        email_comm_template_id: this.email_comm_template_id,
        sms_comm_template_id: this.sms_comm_template_id,
        whatsapp_comm_template_id: this.whatsapp_comm_template_id,
        module_id: this.requestForm.selectedModule.id
      };
      this.loader = true;
      this.$store
        .dispatch('requestMaster/addrequestList', payload)
        .then(response => {
          this.loader = false;
          this.$emit('updateRequestList');
          this.showAlert = true;
          if (response.status === 201) {
            this.requestForm.request_id = response.data.data.request_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, commTemp) {
      this.vsetCode = vsetCode;
      this.commTempName = commTemp;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.COMM_TEMPLATE) {
        if(this.commTempName === 'email') {
            this.email_comm_template = item.value_code;
            this.email_comm_template_id = item.value_set_dtl_id;
        } else if(this.commTempName === 'sms') {
            this.sms_comm_template = item.value_code;
            this.sms_comm_template_id = item.value_set_dtl_id;
        } else if(this.commTempName === 'whatsapp') {
            this.whatsapp_comm_template = item.value_code;
            this.whatsapp_comm_template_id = item.value_set_dtl_id;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getRequestType() {
      this.loader = true;
      this.$store
        .dispatch('requestMaster/getLOVBySetType', 'REQUEST_TYPE')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.requestTypeArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },
    selectedModuleData(module) {
      this.requestForm.selectedModule.name = module.module_name;
      this.requestForm.selectedModule.id = module.module_id;
      this.showModal = false;
    },
  },
  destroyed() {
    this.eventBus.$off('requestFormHeader');
  }
};
